/*--------------------------------

	Fichier JS Startup

*/
// Ajout div qui wrap les simpleText pour témoignages

$(document).ready(function(){
	$( "form" ).submit(function( event ) {spLoaderOn();});	
	
	//Navigation
	$( ".secondLevel" ).parent().addClass("has-dropdown");
	$( ".thirdLevel" ).parent().addClass("has-dropdown");
		
	if (window.matchMedia("(max-width:767px)").matches) {
		$("#nested").insertBefore("#page");
		$(".title-area").clone().addClass("clone").insertAfter("#nested");
		
		$(".toggle-topbar a").on('click', function() { 
			
			$("body").toggleClass("open");
			//ajoute un delay sur le toggleclass "tranform" pour eviter le conflit avec fancybox
			if ($("body").hasClass('transform')) {
				 var delay = 800;
				 setTimeout(function(){
						 $("body").removeClass('transform');
				 },delay );
			 }
			 else {
				 $("body").addClass('transform');
			}

		});
		$('.has-dropdown > a:first-child').each(function(){
			$(this).click(function(e) {
				e.preventDefault();
				$(this).next(".dropdown").slideToggle('fast');
			});
		});
	}
	if (window.matchMedia("(min-width:1024px)").matches) {
		$('.gallery').each(function(){
			if($(this).find("li:nth-child(4)").length == 0) {
					$(this).find(".dragscroll").addClass("center");
			}
		});
	}
	
	// Ajout de la class inView quand l'élément est dans la fenêtre d'affichage
	$('article > *, .article-list-item, .gallery li, .realisation-gallery-zoom > * ').bind('inview',function(event, isInView, visiblePartX, visiblePartY){
		$(this).addClass("inView");	
	});
	if (window.matchMedia("(min-width:1248px)").matches) {
		$(' .carroussel-item').bind('inview',function(event, isInView, visiblePartX, visiblePartY){
			$(this).addClass("inView");	
		});
	}
	// Ajout de classes tactile ou noTactile selon type d'ecran
	if( !( 'ontouchstart' in window ) &&
		!navigator.msMaxTouchPoints &&
		!navigator.userAgent.toLowerCase().match( /windows phone os 7/i ) ) {
			$("body").addClass("noTactile");
	}else{
		$("body").addClass("tactile");
		$( '#nested ul.firstLevel>li:has(ul)' ).doubleTapToGo();
	}

	//Click event to scroll to top
	$('.scroll-top').click(function(){
		$('html, body').animate({scrollTop : 0},400);
		return false;
	});
		
	// Animation au clic sur un lien avec ancre
	$('.js-scrollTo').on('click', function() { // Au clic sur un élément
		var page = $(this).attr('href'); // Page cible
		var speed = 400; // Durée de l'animation (en ms)
		$('html, body').animate( { scrollTop: $(page).offset().top-20 }, speed ); // Go
		return false;
	});

	//Tableaux responsive
	$( "table:not(.calendar)" ).wrap("<div class='table-responsive'></div>");
	
	//OBJECTFIT POLYFILL
	var userAgent,ieReg,ie;userAgent=window.navigator.userAgent,(ie=(ieReg=/msie|Trident.*rv[ :]*11\./gi).test(userAgent))&&($(".objectFitCover").each(function(){var e=$(this),s=e.find("img").prop("src");s&&e.css("backgroundImage","url("+s+")").css("background-size","cover").css("background-repeat","no-repeat").addClass("custom-object-fit")}),$(".objectFitContain").each(function(){var e=$(this),s=e.find("img").prop("src");s&&e.css("backgroundImage","url("+s+")").css("background-size","contain").css("background-repeat","no-repeat").addClass("custom-object-fit")}));

	if (document.documentMode || /Edge/.test(navigator.userAgent)) {
		$("html").addClass("no-support-grid");
	}
	
});

function spLoaderOn(){
	spLoaderOff();
	var div = $('<div id="spLoader" onclick="spLoaderOff()"></div>');
	$('body').append(div);
}

function spLoaderOff(tempo){
	if(tempo==true){
		setTimeout("spLoaderOff()",200) ;
		return;
	}
	$('#spLoader').remove();
}

// Double Tap to Go

;(function( $, window, document, undefined )
{
	$.fn.doubleTapToGo = function( params )
	{
		this.each( function()
		{
			var curItem = false;
			$( this ).on( 'click', function( e )
			{
				var item = $( this );
				if( item[ 0 ] != curItem[ 0 ] )
				{
					e.preventDefault();
					curItem = item;
				}
			});
			$( document ).on( 'click touchstart MSPointerDown', function( e )
			{
				var resetItem = true,
					parents	  = $( e.target ).parents();
				for( var i = 0; i < parents.length; i++ )
					if( parents[ i ] == curItem[ 0 ] )
						resetItem = false;
				if( resetItem )
					curItem = false;
			});
		});
		return this;
	};
})( jQuery, window, document );